import React, { useState } from 'react'
import { useAppSelector } from 'hooks/redux'
import ButtonLine from 'components/atoms/ButtonLine'
import { formatNameWithNoLimit } from '@qirapagos/lib/utils/common'
import ShareSvg from '../../../assets/svg/ShareSVG'
import DownloadSVG from 'assets/svg/DownloadSVG'
import { Box, Text, useClipboard } from '@chakra-ui/react'
import { purple, gray } from '@qirapagos/lib/theme/colors'
import {
  ViewContainer,
  ViewChangePassword,
  ViewSvgContainer,
  ViewLastLogin
} from './style'
import {
  TextInfo,
  ViewContainerInformation,
  ViewInformation,
  TextFullName,
  TextIconView,
  SVGTouchableOpacity,
  SVGTouchableCopyAlias
} from './styleWeb'
import useSharePdf from 'hooks/useSharePdf'
import Tooltip from 'components/atoms/Tooltip'
import CopyClipboard from 'assets/svg/Copy'
import PencilEditV2 from 'assets/svg/PencilEditV2'
import useNavigation from 'hooks/useNavigation'

interface Props {
  action: {
    title: string
    callback: () => void
  }
}

const MyAccountDetails: React.FC<Props> = ({ action }) => {
  const { account } = useAppSelector(state => state.transfers)
  const { lastLogin } = useAppSelector(state => state.auth)
  const { printRef, onShareHandler } = useSharePdf()
  const { onNavigate } = useNavigation()
  const ACCOUNT_INFO = `${account?.name} ALIAS: ${account?.alias} CVU: ${account?.cvu} CUIT/CUIL: ${account?.contactId}`
  const { onCopy } = useClipboard(ACCOUNT_INFO)
  const { onCopy: onCopyCvu } = useClipboard(account?.cvu)
  const { onCopy: onCopyAlias } = useClipboard(account?.alias)

  const [isPrinting, setIsPrinting] = useState(false)

  const handleShare = async () => {
    await setIsPrinting(true)
    await onShareHandler()
    setIsPrinting(false)
  }

  let formattedDate = ''
  if (lastLogin?.date) {
    const dateObject = new Date(lastLogin.date)
    formattedDate = dateObject.toLocaleString('es-AR', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Argentina/Buenos_Aires'
    })
  }

  const changeAlias = {
    title: 'Cambiar alias',
    callback: () =>
      onNavigate('/content/your-account/details/change-alias', {
        title: 'Mis datos'
      })
  }

  return (
    <ViewContainer>
      {!!formattedDate && (
        <ViewLastLogin>
          <Text
            fontWeight={400}
            color={gray}
            fontSize={{ sm: '20px', md: '15px', lg: '15px' }}
          >
            {`Último ingreso: ${lastLogin.platform}, ${formattedDate}`}
          </Text>
        </ViewLastLogin>
      )}
      <ViewSvgContainer>
        <Tooltip label="Copiar al portapapeles" hasArrow={false} placement="bottom-start">
          <button type="button" onClick={onCopy}>
            <ShareSvg />
          </button>
        </Tooltip>
        <Tooltip label="Descargar comprobante en pdf" hasArrow={false} placement="bottom-start">
          <button type="button" onClick={handleShare}>
            <DownloadSVG />
          </button>
        </Tooltip>
      </ViewSvgContainer>
      <ViewChangePassword>
        <ButtonLine onPress={action.callback}>{action.title}</ButtonLine>
      </ViewChangePassword>
      <Box ref={printRef}>
        <TextFullName>
          {formatNameWithNoLimit(account?.name?.trim())}
        </TextFullName>
        <ViewContainerInformation>
          <ViewInformation>
            <Text
              fontWeight={600}
              color={purple}
              fontSize={{ sm: '30px', md: '20px', lg: '20px' }}
              align="center"
            >
              Alias
            </Text>
            <TextIconView>
              <TextInfo>{account?.alias?.trim()}</TextInfo>
              {
                !isPrinting && (
                <>
                  <SVGTouchableCopyAlias onClick={onCopyAlias}>
                    <Tooltip label="Copiar alias" hasArrow={false}>
                      <CopyClipboard />
                    </Tooltip>
                  </SVGTouchableCopyAlias>
                  <SVGTouchableOpacity onClick={changeAlias.callback}>
                    <Tooltip label="Editar" hasArrow={false}>
                      <PencilEditV2 />
                    </Tooltip>
                  </SVGTouchableOpacity>
                </>
                )
}
            </TextIconView>
          </ViewInformation>
          <ViewInformation>
            <Text
              fontWeight={600}
              color={purple}
              fontSize={{ sm: '30px', md: '20px', lg: '20px' }}
              align="center"
            >
              cvu
            </Text>
            <TextIconView>
              <TextInfo>{account?.cvu?.trim()}</TextInfo>
              {!isPrinting && (
                <SVGTouchableOpacity onClick={onCopyCvu}>
                  <Tooltip label="Copiar cvu" hasArrow={false}>
                    <CopyClipboard />
                  </Tooltip>
                </SVGTouchableOpacity>
              )}
            </TextIconView>
          </ViewInformation>
          <ViewInformation>
            <Text
              fontWeight={600}
              color={purple}
              fontSize={{ sm: '30px', md: '20px', lg: '20px' }}
              align="center"
            >
              Cuit / Cuil
            </Text>
            <TextInfo>{account?.contactId?.trim()}</TextInfo>
          </ViewInformation>
        </ViewContainerInformation>
      </Box>
    </ViewContainer>
  )
}

export default MyAccountDetails
