import React, { useRef, useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Button from 'components/atoms/Button'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { useForm, Controller } from 'react-hook-form'
import PencilEdit from 'assets/svg/PencilEdit'

import {
  ViewContainer,
  ViewContainerInformation,
  TextFullName,
  ViewInformation,
  TextInfo,
  TextInfoMargin,
  customButtonEdit,
  customButtonCancel,
  PencilContainerObs,
  PencilContainerInput,
  InputContainer,
  ObservationsContainer
} from './styles'
import useNavigation from 'hooks/useNavigation'
import { useAppDispatch } from 'hooks/redux'
import { editObservation } from 'store/transfers/thunks'

type FormValues = {
  observation: string
}

export const EditObservation: React.FC = () => {
  const { location, onNavigate } = useNavigation()
  const dispatch = useAppDispatch()
  const { name, cvu, id, observations, taxId, bank } = location.state
  const { control, watch, reset } = useForm<FormValues>({
    mode: 'onChange'
  })
  const [onEdit, setOnEdit] = useState(false)
  const observationInput = watch('observation')

  const inputFocusRef = useRef<HTMLInputElement>(null)

  const editData = {
    id: Number(id),
    observation: observationInput
  }

  const editPencilPress = () => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus()
    }
    setOnEdit(true)
  }

  return (
    <Box mt={5} bg={white} display="flex" flexDirection="column">
      <ViewContainer>
        <TextFullName>{name}</TextFullName>
        <ViewContainerInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CBU / CVU
            </Text>
            <TextInfo>{cvu}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              Banco
            </Text>
            <TextInfo>{bank}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CUIT / CUIL
            </Text>
            <TextInfo>{taxId}</TextInfo>
          </ViewInformation>
          {!onEdit && observations
            ? (
              <ViewInformation>
                <Text
                  color={purple}
                  fontWeight={600}
                  fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
                  align="center"
                >
                  Observaciones
                </Text>
                <ObservationsContainer>
                  <TextInfoMargin>
                    {observations}
                  </TextInfoMargin>
                </ObservationsContainer>
                <PencilContainerObs onClick={editPencilPress}>
                  <PencilEdit />
                </PencilContainerObs>
              </ViewInformation>
              )
            : (
              <ViewInformation>
                <InputContainer>
                  <Controller
                    control={control}
                    name="observation"
                    render={({ value, onChange }) => {
                      return (
                        <input
                          type="text"
                          value={value}
                          placeholder='Observaciones'
                          ref={inputFocusRef}
                          onChange={onChange}
                          onFocus={() => setOnEdit(true)}
                        />
                      )
                    }}
                  />
                  <PencilContainerInput onClick={editPencilPress}>
                    <PencilEdit />
                  </PencilContainerInput>
                </InputContainer>
              </ViewInformation>
              )}
        </ViewContainerInformation>
        <Box display="flex" flex={1} flexDir="row" alignSelf="flex-end">
          {(onEdit || observationInput) && (
          <Button
            onPress={() => { setOnEdit(false); reset({ }) }}
            customStyle={customButtonCancel}
          >
            Cancelar
          </Button>
          )}
          <Button
            disabled={!onEdit}
            onPress={() => {
              dispatch(editObservation(editData?.id, editData?.observation))
              onNavigate(-1)
            }}
            customStyle={customButtonEdit}
          >
            Editar
          </Button>
        </Box>
      </ViewContainer>
    </Box>
  )
}

export default EditObservation
