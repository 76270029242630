import { ThunkDispatchType } from 'store'
import * as LegalDocumentActionTypes from 'store/legal-document/actions'
import { LegalDocumentServices } from 'services'

export const getAcceptedLegalDocuments =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LegalDocumentActionTypes.getAcceptedLegalDocumentsPending())

      const response = await LegalDocumentServices.getAcceptedLegalDocuments()

      dispatch(
        LegalDocumentActionTypes.getAcceptedLegalDocumentsFulfilled(
          response.data.data
        )
      )
    } catch (error) {
      dispatch(
        LegalDocumentActionTypes.acceptLegalDocumentRejected(error as Error)
      )
    }
  }

export const getPendingLegalDocuments =
  () => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LegalDocumentActionTypes.getPendingLegalDocumentsPending())

      const response = await LegalDocumentServices.getPendingLegalDocuments()

      dispatch(
        LegalDocumentActionTypes.getPendingLegalDocumentsFulfilled(
          response.data.data
        )
      )
    } catch (error) {
      dispatch(
        LegalDocumentActionTypes.acceptLegalDocumentRejected(error as Error)
      )
    }
  }

export const acceptLegalDocument =
  (legalDocumentId: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(LegalDocumentActionTypes.acceptLegalDocumentPending())

      const response = await LegalDocumentServices.acceptLegalDocument(
        legalDocumentId
      )

      dispatch(
        LegalDocumentActionTypes.acceptLegalDocumentFulfilled(
          response.data.data.legalDocumentId
        )
      )
    } catch (error) {
      dispatch(
        LegalDocumentActionTypes.acceptLegalDocumentRejected(error as Error)
      )
    }
  }
