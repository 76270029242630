import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'hooks/redux'
import { getQuota, getSaleOrder } from 'store/contracts/thunks'
import {
  IApplication,
  ILiquidation,
  Quota,
  SaleOrderStatus
} from 'store/contracts/types'
import ActivityDetailsSkeleton from 'assets/skeletons/ActivityDetailsSkeleton'
import Stepper from './Stepper'
import Table, { TABLE } from 'components/organisms/TableV2'
import QuoteRequest, { StatusQuota } from './QuoteRequest'
import {
  Header,
  Title,
  TableContainer,
  Column,
  Liquidation,
  Application,
  Container
} from './styles'
import { showDrawer } from 'store/drawer/actions'
import { Drawers } from 'store/drawer/types'
import { selectApplication, selectLiquidation } from 'store/contracts/actions'
import { formatStringDateType } from 'utils/date'
import { RootState } from 'store'
import CustomText from 'components/atoms/CustomText'

export enum Stages {
  INIT = 1,
  CONFIRMATION = 2,
  QUOTES = 3,
  APPLICATIONS = 4,
  LIQUIDATIONS = 5
}

export const StatusQuotaString: { [key: number]: string } = {
  [StatusQuota.AWARDED]: 'Otorgado',
  [StatusQuota.REQUIRED]: 'Solicitado',
  [StatusQuota.REJECTED]: 'Rechazado',
  [StatusQuota.ANNULLED]: 'Anulado'
}

export enum Status {
  FINISHED = 'FI',
  ANNULLED = 'AN'
}

export const LiquidationStatus: { [key: string]: string } = {
  [Status.FINISHED]: 'Finalizada',
  [Status.ANNULLED]: 'Anulada'
}

const ActivityGrains: React.FC = () => {
  const { saleOrderId } = useParams()

  const { isLoading, selected } = useAppSelector(state => state.contractsV2)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!selected || selected.id !== Number(saleOrderId)) {
      dispatch(getSaleOrder(Number(saleOrderId)))
    }
  }, [dispatch, saleOrderId, selected])

  const { isRepresentative } = useSelector(
    (state: RootState) => state.representation
  )

  const currentStepValues = useMemo(() => {
    if (selected?.agreement) {
      // eslint-disable-next-line no-constant-condition
      if (selected?.liquidations?.length) {
        return {
          stage: Stages.LIQUIDATIONS,
          table: {
            title: 'Etapa 5 - Liquidaciones',
            data: selected.liquidations.map(liquidation => ({
              id: liquidation.id,
              currency: liquidation.currency,
              price: liquidation.price,
              date: formatStringDateType(liquidation.date ?? '', 'yy-MM-dd'),
              status: LiquidationStatus[liquidation.status ?? '']
            })),
            config: [
              {
                id: 'id',
                header: 'ID',
                meta: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  formatter: (value: string) => {
                    return (
                      <Column>
                        <Liquidation />
                        {value}
                      </Column>
                    )
                  }
                }
              },
              { id: 'currency', header: 'Moneda' },
              { id: 'price', header: 'Precio' },
              { id: 'date', header: 'Fecha' },
              { id: 'status', header: 'Estado' },
              {
                id: TABLE.ACTION,
                header: '',
                meta: {
                  actions: ({
                    row: { original }
                  }: {
                    row: { original: ILiquidation }
                  }) => [
                    {
                      title: 'Ver detalle',
                      onPress: () => {
                        const selectedLiquidation = selected?.liquidations?.find(
                          liquidation => liquidation.id === original.id
                        )
                        if (selectedLiquidation) {
                          dispatch(selectLiquidation(selectedLiquidation))
                        }
                        dispatch(showDrawer(Drawers.LIQUIDATIONS_DETAILS))
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      } else if (selected?.applications?.length) {
        return {
          stage: Stages.APPLICATIONS,
          table: {
            title: 'Etapa 4 - Aplicaciones de entregas de contratos',
            data: selected.applications.map(application => ({
              id: application.id,
              agreement: application.agreement,
              tempCarPorte: application.tempCarPorte,
              tons: application.tempAppliedKgs
                ? application.tempAppliedKgs / 1000 + 'TT'
                : 0 + 'TT',
              quality: application?.grainQuality
            })),
            config: [
              {
                id: 'agreement',
                header: 'Nro. de contrato',
                meta: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  formatter: (value: string) => {
                    return (
                      <Column>
                        <Application />
                        {value}
                      </Column>
                    )
                  }
                }
              },
              { id: 'tempCarPorte', header: 'Nro. de Porte' },
              { id: 'tons', header: 'Toneladas' },
              { id: 'quality', header: 'Calidad' },
              {
                id: TABLE.ACTION,
                header: '',
                meta: {
                  actions: ({
                    row: { original }
                  }: {
                    row: { original: IApplication }
                  }) => [
                    {
                      title: 'Ver detalle',
                      onPress: () => {
                        const selectedApplication =
                          selected?.applications?.find(
                            application => application.id === original.id
                          )
                        if (selectedApplication) {
                          dispatch(selectApplication(selectedApplication))
                        }
                        dispatch(showDrawer(Drawers.APPLICATIONS_DETAILS))
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      } else {
        let data: Quota[] = []
        // eslint-disable-next-line no-constant-condition
        if (selected.Quota) {
          data = selected?.Quota?.map((quota: Quota) => {
            const index =
              typeof quota.status === 'string'
                ? parseInt(quota.status, 10)
                : quota.status || 1
            return {
              myQuotas: 'Cupo solicitado',
              deliveryDate: quota.deliveryDate,
              weight: quota.weight,
              Status: StatusQuotaString[index],
              id: quota.id
            }
          })
        }
        return {
          stage: Stages.QUOTES,
          table: {
            title: 'Etapa 3 - Cupos de entrega',
            data: data,
            config: [
              { id: 'myQuotas', header: 'Mis cupos' },
              { id: 'deliveryDate', header: 'Entrega' },
              { id: 'weight', header: 'Toneladas' },
              { id: 'Status', header: 'Estado' },
              {
                id: TABLE.ACTION,
                header: '',
                meta: {
                  actions: ({
                    row: { original }
                  }: {
                    row: { original: Quota }
                  }) => [
                    {
                      title: 'Ver detalle',
                      onPress: () => {
                        dispatch(getQuota(original.id))
                        dispatch(showDrawer(Drawers.QUOTA_DETAILS))
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      }
    } else if (selected?.status === SaleOrderStatus.MIGRATED && !selected?.agreement) {
      return { stage: Stages.CONFIRMATION }
    }
    return { stage: Stages.INIT }
  }, [selected, dispatch])

  if (isLoading || !selected) {
    return <ActivityDetailsSkeleton />
  }

  const showStageDetails = !(selected.Representation?.active && isRepresentative)

  const isMigrated = selected.status === SaleOrderStatus.MIGRATED

  return (
    <Container>
      <Helmet title="Mi orden" />
      <Header>
        <Title>
          Orden de venta #
          {saleOrderId?.padStart(4, '0')}
        </Title>
        <Stepper currentStage={currentStepValues.stage} />
      </Header>
      {isMigrated && (
        currentStepValues.table && showStageDetails
          ? (
            <TableContainer>
              <Table
                data={currentStepValues.table.data}
                title={currentStepValues.table.title}
                config={currentStepValues.table.config}
                emptyText="Aún no tenes ningún cupo solicitado"
              />
              {currentStepValues.stage === Stages.QUOTES && (
                <QuoteRequest saleOrder={selected} />
              )}
            </TableContainer>
            )
          : (
              currentStepValues.stage !== Stages.INIT &&
              currentStepValues.stage !== Stages.CONFIRMATION && (
              <CustomText weight="semibold" size="mmbig">
                Desde ahora, tu representado se encargará de gestionar este proceso
              </CustomText>
              )
            )
      )}
    </Container>
  )
}

export default ActivityGrains
