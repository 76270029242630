export interface IMovement {
  alias: string
  amount: number
  cbu?: string
  cvu?: string
  creditAccount: string
  cuit: string
  date: string
  debitAccount: string
  description: string
  destination: string
  destino: string
  id: number
  movementType: {
    id: number
    typeDescription: string
  }
  owner: string
  source: string
  status: number
  timestamp: string
}
export interface IContact {
  id?: number;
  idClient?: number;
  account?: string;
  taxId?: string;
  cvu?: string;
  email?: string;
  entity?: string;
  name?: string;
  observations?: string;
  contactId?: string;
}

export interface ITransferAccount {
  accountId?: number
  destinationId?: string
  amount?: number
}

export interface IAccount {
  alias: string
  bank: string
  contactId: string
  cvu: string
  name: string
  transactionLimit: number
}

export interface TransfersState {
  accountId?: number
  accountNumber?: string
  balance: string
  movements: IMovement[] | []
  filteredMovements?: IMovement[]
  contacts?: IContact[]
  currentPage: number
  countPages: number
  showModal: boolean
  showBalance: boolean
  isLoading: boolean
  isLoadingStatements: boolean
  transferAccount: ITransferAccount
  account: IAccount
  isInfoLoading: boolean
  isLoadingCreateTransfer: boolean
}

export enum TransfersActionTypes {
  GET_MOVEMENTS = '@TRANSFERS:GET_MOVEMENTS',
  GET_CONTACTS = '@TRANSFERS:GET_CONTACTS',
  GET_BALANCE = '@TRANSFERS:GET_BALANCE',
  GET_ACCOUNT_INFO = '@TRANSFERS:GET_ACCOUNT_INFO',
  CLEAR_ACCOUNT_INFO = '@TRANSFERS:CLEAR_ACCOUNT_INFO',
  GET_ACCOUNT_ID = '@TRANSFERS:GET_ACCOUNT_ID',
  SET_SHOW_BALANCE = '@TRANSFERS:SET_SHOW_BALANCE',
  SET_LOADING = '@TRANSFERS:SET_LOADING',
  SET_LOADING_STATEMENTS = '@TRANSFERS:SET_LOADING_STATEMENTS',
  GET_TRANSFER_ACCOUNT = '@TRANSFERS:GET_TRANSFER_ACCOUNT',
  DISMISS_LOADING = '@TRANSFERS:DISMISS_LOADING',
  SET_FILTERED_MOVEMENTS = '@TRANSFERS:SET_FILTERED_MOVEMENTS',
  GET_SHOW_BALANCE = '@TRANSFERS:GET_SHOW_BALANCE',
  SET_TRANSFER_LIMIT = '@TRANSFERS:SET_TRANSFER_LIMIT',
  LOGOUT = '@TRANSFERS:LOGOUT',
  SET_ACCOUNT_LOADING = '@TRANSFERS:SET_ACCOUNT_LOADING',
  DISMISS_ACCOUNT_LOADING = '@DISMISS_ACCOUNT_LOADING',
  GET_TRANSFERS_REJECTED = '@TRANSFERS:GET_TRANSFERS_REJECTED',
  SET_LOADING_CREATE_TRANSFER = '@SET_LOADING_CREATE_TRANSFER',
  DISMISS_LOADING_CREATE_TRANSFER = '@DISMISS_LOADING_CREATE_TRANSFER'
}

export enum ContactsActionTypes {
  NEW_CONTACT = '@TRANSFERS:NEW_CONTACT',
  DELETE_CONTACT = '@TRANSFERS:DELETE_CONTACT',
  EDIT_OBSERVATION = '@TRANSFERS:EDIT_OBSERVATION'
}
