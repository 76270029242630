import styled from 'styled-components'
import DrawerComponent from 'react-modern-drawer'
import Button from 'components/atoms/Button'
import { ReactComponent as SuccessTickIcon } from 'assets/svg/SuccessTick.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px;
`
export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10%;
`

export const BackArrowButton = styled.button`
  position: absolute;
  top: 5%;
  left: 25px;
`

export const Title = styled.p`
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
`
export const Body = styled.p`
  margin-bottom: 50px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`

export const Drawer = styled(DrawerComponent)`
  width: 100% !important;
  @media (min-width: 600px) {
    width: 450px !important;
  }
`

export const ButtonStyled = styled(Button)`
  width: 60% !important;
  height: 60px !important;
  border-radius: 5px !important;
  margin-bottom: 20% !important;
  cursor: ${props => props.disabled && 'default'};
`

export const SuccessTick = styled(SuccessTickIcon)``
