import React from 'react'
import Handshake from '@qirapagos/lib/assets/svg/Handshake'
import TransferRecieve from '@qirapagos/lib/assets/svg/TransferReceive'
import TransferSend from '@qirapagos/lib/assets/svg/TransferSend'
import DollarSignSVG from '../../../assets/svg/DollarSignSVG'

export const selectIcon = {
  'Crédito Transferencia': <TransferRecieve />,
  transferSend: <TransferSend />,
  saleOrder: <DollarSignSVG active width={31} height={32} />,
  tradeOrder: <Handshake />
}

export const selectTitle = {
  'Crédito Transferencia': 'Recibido',
  transferSend: 'Enviado',
  saleOrder: 'Venta',
  tradeOrder: 'Compra'
}

export enum QuotaStatus {
  GRANTED= 'otorgado',
  REQUESTED= 'solicitado',
  REJECTED='rechazado'
}

export enum MovementStatus {
  APPROVED= 1,
  PENDING= 2,
  REJECTED= 3
}
