import { white, grayText, purple } from '@qirapagos/lib/theme/colors'
import styled from 'styled-components'
import ButtonComponent from '@qirapagos/web/src/components/atoms/Button'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  background-color: ${white};
  margin-left: 50px;
  margin-top: 3.65vh;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  padding: 5%;
`

export const Title = styled.h2`
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1.3em;
`

export const SubTitle = styled.p`
  margin-top: 5%;
  margin-bottom: 4%;
  font-size: 1em;
  color: ${grayText};
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  width: 35%;
  margin-bottom: 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  max-width: 1000px;
  width: 55%;
`

export const Button = styled(ButtonComponent)`
  max-width: 300px !important;
  width: 45% !important;
  margin-bottom: 0 !important;
`

export const FieldWithCode = styled.div`
  background-color: aqua;
  display: flex;
  flex-direction: row;
  height: auto;
`

export const PhoneContainer = styled.div``

export const AreaCode = styled.text`
  display: flex;
  position: relative;
  width: 32px;
  top: 54px;
  left: 21px;
  z-index: 10;
`

export const CustomText = styled.div`
  color: ${purple};
`
