import { LegalDocument, LegalDocumentActionTypes } from './types'

export const getAcceptedLegalDocumentsRejected = (error: unknown) => ({
  type: LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_REJECTED,
  payload: error
})

export const getAcceptedLegalDocumentsPending = () => ({
  type: LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_PENDING
})

export const getAcceptedLegalDocumentsFulfilled = (
  acceptedLegalDocuments: LegalDocument[]
) => ({
  type: LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_FULFILLED,
  payload: acceptedLegalDocuments
})

export const getPendingLegalDocumentsRejected = (error: unknown) => ({
  type: LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_REJECTED,
  payload: error
})

export const getPendingLegalDocumentsPending = () => ({
  type: LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_PENDING
})

export const getPendingLegalDocumentsFulfilled = (
  pendingLegalDocuments: LegalDocument[]
) => ({
  type: LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_FULFILLED,
  payload: pendingLegalDocuments
})

export const acceptLegalDocumentRejected = (error: unknown) => ({
  type: LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_REJECTED,
  payload: error
})

export const acceptLegalDocumentPending = () => ({
  type: LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_PENDING
})

export const acceptLegalDocumentFulfilled = (legalDocumentId: number) => ({
  type: LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_FULFILLED,
  payload: legalDocumentId
})
