export interface ActivityMovement {
  id: number
  movementType: string
  amount?: string
  status?: number
  date?: Date
  owner?: string
  originOper?: number
  originAccount?: string
  originClient?: string
  originCUIT?: string
  creditAccount?: string
  movementTypeId?: number
  cuit?: string
  cbu?: string
  account?: string
}

export enum ActivityActionTypes {
  GET_ACTIVITY_PENDING = '@ACTIVITY:GET_ACTIVITY_PENDING',
  GET_ACTIVITY_FULFILLED = '@ACTIVITY:GET_ACTIVITY_FULFILLED',
  GET_ACTIVITY_REJECTED = '@ACTIVITY:GET_ACTIVITY_REJECTED',
  LOGOUT = '@ACTIVITY:LOGOUT'
}

export interface ActivityState {
  activity: ActivityMovement[] | null
  isLoading: boolean
}
