import { useEffect, useState } from 'react'
import {
  ALIAS_CHARACTERS,
  SPECIAL_LETTERS
} from '@qirapagos/web/src/constants/validations'

const useCheckAlias = (alias: string) => {
  const [checkAliasLength, setCheckAliasLength] = useState(false)
  const [checkCharacters, setCheckCharacters] = useState(false)
  const [checkSpecialLetters, setCheckSpecialLetters] = useState(false)

  useEffect(() => {
    setCheckAliasLength(alias.length >= 6 && alias.length <= 20)
    setCheckCharacters(Boolean(alias.match(ALIAS_CHARACTERS)))
    if (alias.length > 0) {
      setCheckSpecialLetters(Boolean(alias.match(SPECIAL_LETTERS)))
    } else {
      setCheckSpecialLetters(false)
    }
  }, [alias])

  return {
    checkAliasLength,
    checkCharacters,
    checkSpecialLetters
  }
}

export default useCheckAlias
