/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

function FixRejected () {
  return (
    <Svg width={44} height={43} viewBox="0 0 44 43" fill="none">
      <Path
        d="M42 21.5c0 11.035-9.048 20-20.232 20-11.185 0-20.233-8.965-20.233-20s9.048-20 20.233-20C32.952 1.5 42 10.465 42 21.5z"
        fill="#fff"
        stroke="#FF50A4"
        strokeWidth={2}
      />
      <Path
        d="M16.42 16.385l10.416 10.416M26.837 16.385L16.42 26.801"
        stroke="#FF70B4"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default FixRejected
