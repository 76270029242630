import React from 'react'
import CustomText from 'components/atoms/CustomText'
import { ItemContainer, CircleMark } from './styles'

interface Props {
  checkCapitalLetter: boolean
  checkLoweCase: boolean
  checkNumber: boolean
  checkSpecialCharacter: boolean
  checkPasswordLength: boolean
}

const Item = ({ children, isActive }: { children: React.ReactChild, isActive: boolean }) => (
  <ItemContainer>
    <CircleMark isActive={isActive} />
    <CustomText
      size="medium"
      color="gray"
      align="left"
    >
      {children}
    </CustomText>
  </ItemContainer>
)

const PasswordCheckers: React.FC<Props> = ({
  checkCapitalLetter,
  checkLoweCase,
  checkNumber,
  checkSpecialCharacter,
  checkPasswordLength
}) => {
  return (
    <div>
      <Item isActive={checkCapitalLetter}>
        Un carácter en mayúscula
      </Item>
      <Item isActive={checkLoweCase}>
        Un carácter en minúscula
      </Item>
      <Item isActive={checkNumber}>
        Un número
      </Item>
      <Item isActive={checkSpecialCharacter}>
        Un carácter especial
      </Item>
      <Item isActive={checkPasswordLength}>
        Diez caracteres mínimo
      </Item>
    </div>
  )
}

export default PasswordCheckers
