import React from 'react'
import {
  Drawer,
  Container,
  ContentContainer,
  Header,
  Title,
  Body,
  ButtonStyled,
  ErrorCross
} from './style'
import { Box } from '@chakra-ui/react'
import { Drawers } from 'store/drawer/types'
import wrapper from 'components/organisms/Drawers/wrapper'
import useNavigation from 'hooks/useNavigation'

interface Props {
  showDrawer: boolean;
  onClose: () => void;
}

const DocumentsSentError: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { onNavigate } = useNavigation()

  const onPress = () => {
    onClose()
    onNavigate('/content/your-enterprises/new-enterprise')
  }

  return (
    <Drawer
      open={showDrawer}
      onClose={onClose}
      direction="right"
    >
      <Container>
        <Header />
        <ContentContainer>
          <ErrorCross />
          <Title>Ocurrió un error.</Title>
          <Body> Por favor, volvé a intentralo.</Body>
        </ContentContainer>
        <Box display="flex" flexDir="column" width="100%">
          <ButtonStyled
            onPress={onPress}
            disabled={false}
          >
            Reintentar
          </ButtonStyled>
        </Box>
      </Container>
    </Drawer>
  )
}

export default wrapper(DocumentsSentError, Drawers.DOCUMENTS_SENT_ERROR)
