import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'components/molecules/Field'
import Helmet from 'components/utilities/Helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { changePhone, getMyData } from 'store/auth/thunks'
import {
  setShowDrawerError,
  setShowDrawerSuccess
} from 'store/modals/actions'
import { SCREEN_TITLES } from 'utils/navigation'
import { RenderIf, FieldSelectCountry } from 'components'
import { ErrorMessages } from '@qirapagos/lib/utils/common'
import useNavigation from 'hooks/useNavigation'
import {
  Title,
  Button,
  SubTitle,
  Container,
  FieldContainer,
  ButtonContainer,
  CustomText,
  PhoneContainer,
  AreaCode
} from './styles'
import { setTwoFactorVerified } from '@qirapagos/lib/store/auth/actions'
import TwoFactorAuthentication from 'components/molecules/TwoFactorAuthentication'
import { RegPhoneArg, RegPhone } from 'constants/validations'
import { Countries } from 'constants/common'
import extractPhoneNumberInfo from './utils'

const Steps = {
  TWO_FACTOR: 'TWO_FACTOR',
  PHONECHANGE: 'PHONECHANGE'
}

const ChangePhoneMyData = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(Steps.PHONECHANGE)
  const [newPhone, setNewPhone] = useState('')
  const [bodyPhone, setBodyPhone] = useState({ phone: '', areaCode: '' })
  const { isLoading, myData } = useSelector((state: any) => state.auth)
  const [countrySelectedId, setCountrySelectedId] = useState(1)
  const [isSamePhone, setSamePhone] = useState(false)
  const [focus, setFocus] = useState(false)

  const { control, handleSubmit, errors, clearErrors, setError, watch, setValue } =
    useForm({
      defaultValues: {
        phone: '',
        country: ''
      }
    })
  const { onNavigate } = useNavigation()

  const phoneEmpty = !watch('phone')
  const watchPhone = watch('phone')
  const countryWatch = watch('country')

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getMyData())
    }
    fetchData()

    if (myData?.telephoneNumber) {
      const { areaCode, phone } = extractPhoneNumberInfo(myData.telephoneNumber)
      setValue('phone', phone)
      setBodyPhone(({ phone, areaCode }))

      const country = Countries.find((country) => country.code.toString() === areaCode)

      if (country) {
        setCountrySelectedId(country.id)
        setValue('country', country.country)
      }
    }
  }, [dispatch, myData?.telephoneNumber, setValue])

  useEffect(() => {
    if (myData?.telephoneNumber) {
      const { areaCode, phone } = extractPhoneNumberInfo(myData.telephoneNumber)
      const country = Countries.find((country) => country.code.toString() === areaCode)?.country
      setSamePhone(watchPhone === phone && countryWatch === country)
    }
  }, [countryWatch, myData?.telephoneNumber, watchPhone])

  const code = Countries.find((country) => country.id === countrySelectedId)?.code

  const updatePhone = useCallback(async () => {
    try {
      await dispatch(changePhone(bodyPhone))
      dispatch(
        setShowDrawerSuccess(
          true,
          true,
          '¡Listo!',
          'Editaste tu teléfono.',
          {
            path: '/content/my-data'
          }
        )
      )
    } catch (error: any) {
      dispatch(
        setShowDrawerError(
          true,
          error?.toString() || ErrorMessages.PHONE_EDIT_ERROR,
          '',
          {
            path: '/content/my-data'
          }, 'Reintentar'
        )
      )
    }
  }, [dispatch, bodyPhone])

  const onCancel = useCallback(() => {
    onNavigate('/content/my-data', { title: 'Mis datos' })
    dispatch(setTwoFactorVerified(false))
  }, [onNavigate, dispatch])

  const onPressChange = useCallback((values) => {
    let tested: boolean

    if (countrySelectedId !== 1) {
      tested = RegPhone.test(values.phone)
    } else {
      tested = RegPhoneArg.test(values.phone)
    }

    if (tested) {
      setNewPhone(`${code?.toString()}${values.phone}`)
      setBodyPhone({ areaCode: code?.toString() || '', phone: values.phone })
      setStep(Steps.TWO_FACTOR)
    } else {
      setError('phone', { message: 'El formato del teléfono es incorrecto' })
    }
  }, [code, setError, countrySelectedId])

  useEffect(() => {
    clearErrors('country')
  }, [clearErrors, countryWatch])

  const isDisabled = isLoading || phoneEmpty || watchPhone.length < 10 || watchPhone.length > 14 || isSamePhone

  return (
    <>
      <RenderIf isTrue={step === Steps.TWO_FACTOR}>
        <TwoFactorAuthentication
          onlyBySMS
          callback={updatePhone}
          newPhone={newPhone}
        />
      </RenderIf>
      <RenderIf isTrue={step === Steps.PHONECHANGE}>
        <Container>
          <Helmet title={SCREEN_TITLES.CHANGE_PHONE} />
          <Title>Editar teléfono</Title>
          <FieldContainer>
            <FieldSelectCountry
              data={Countries}
              control={control}
              name="country"
              label="Código país"
              editable={false}
              accessibilityLabelValue="CountrySelector"
              setValue={setValue}
              setSelectedID={setCountrySelectedId}
              selected={countrySelectedId}
              error={errors.country}
              errorMessage={{
                required: 'Debe seleccionar un país'
              }}
              clearErrors={clearErrors}

            />
            <SubTitle>
              Ingresá tu número de teléfono sin el 0 y sin el 15
            </SubTitle>
            <PhoneContainer>
              <AreaCode>
                {(focus || watchPhone) && (
                  <CustomText>
                    +
                    {code}
                  </CustomText>
                )}
              </AreaCode>
              <Field
                control={control}
                name="phone"
                customStyle={{ paddingLeft: (focus || watchPhone) ? 65 : 20 }}
                onFocus={() => setFocus(true)}
                onComponentBlur={() => setFocus(false)}
                placeholder="Teléfono"
                error={errors.phone}
                type="number"
                clearErrors={clearErrors}
                alignError="left"
              />
            </PhoneContainer>
          </FieldContainer>
          <ButtonContainer>
            <Button secondary onPress={onCancel} disabled={isLoading}>
              Cancelar
            </Button>
            <Button
              onPress={handleSubmit(onPressChange)}
              disabled={isDisabled}
              loading={isLoading}
            >
              Cambiar teléfono
            </Button>
          </ButtonContainer>
        </Container>
      </RenderIf>
    </>
  )
}

export default ChangePhoneMyData
