import * as Sentry from '@sentry/react'
import * as TransfersActions from '@qirapagos/lib/store/transfers/actions'
import { ThunkDispatchType } from '@qirapagos/lib/interfaces/reduxInterfaces'
import { AccountServices, TransferServices } from 'services'
import { ErrorMessages, numberWithCommas } from '@qirapagos/lib/utils/common'
import { getToken } from 'services/utils'
import { IContact } from '@qirapagos/lib/store/transfers/types'
import { RootState } from 'types/redux'
import { setShowDrawerError, setShowDrawerSuccess } from 'store/modals/actions'
import { IDataContact } from 'screens/Home/YourAccount/MyContacts/AddContact/types'

export const getUserBalance = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(TransfersActions.setTransferLoading())
    // TODO: guardar el token del login en localstorage y consumirlo acá
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: any = await TransferServices.getBalance(
      token,
      keyEndpoints as string
    )
    Sentry.setExtras({
      data: {
        response: response.data,
        token,
        keyEndpoints
      }
    })
    if (response.status !== 200 && response?.data?.error_message) {
      throw ErrorMessages.DEFAULT_BACKEND_ERROR
    }
    dispatch(
      TransfersActions.setBalance(numberWithCommas(response.data.balance))
    )
    dispatch(TransfersActions.setAccountId(response.data.accountId))
    dispatch(TransfersActions.dismissTransferLoading())
  } catch (error) {
    Sentry.captureMessage(`Error al conseguir balance:${error}`, 'error')
    dispatch(TransfersActions.dismissTransferLoading())
  }
}

export const getAccountInfo = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(TransfersActions.setAccountLoading())
    const token = await getToken()
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const idClient = await localStorage.getItem('clientId')
    const response: any = await AccountServices.accountInfo(
      idClient,
      token,
      keyEndpoints as string
    )
    Sentry.setExtras({
      data: {
        response: response.data,
        token,
        keyEndpoints,
        idClient
      }
    })
    dispatch(TransfersActions.setAccountInfo(response.data))
    if (response.data.error_code) throw new Error()
    dispatch(TransfersActions.dismissInfoLoading())
    return response.data
  } catch (error) {
    Sentry.captureMessage(
      `Error al conseguir info de la cuenta:${error}`,
      'warning'
    )
    dispatch(TransfersActions.dismissTransferLoading())
  }
}

export const getTransfers =
  (limit: number | undefined) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(TransfersActions.setTransferLoading())
      const token = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const idClient = await localStorage.getItem('clientId')
      const body = {
        idclient: idClient,
        page: 1,
        limit
      }
      const response: any = await TransferServices.transactions(
        body,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          token,
          keyEndpoints,
          body
        }
      })
      const { balance, movements, page, countPages } = response.data
      dispatch(TransfersActions.setBalance(numberWithCommas(balance)))
      dispatch(
        TransfersActions.setMovements({
          movements,
          currentPage: page,
          countPages
        })
      )
      dispatch(TransfersActions.dismissTransferLoading())
    } catch (error) {
      Sentry.captureMessage(
        `Error al conseguir transferencias:${error}`,
        'error'
      )
      dispatch(TransfersActions.getTransfersRejected())
    }
  }

export const getStatement =
  (year: number, month: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(TransfersActions.setStatementsLoading(true))
      const token = await getToken()
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const idClient = await localStorage.getItem('clientId')
      const response: any = await TransferServices.getStatement(
        idClient as string,
        year,
        month,
        token,
        keyEndpoints as string
      )
      window.open(response.data.url, '_blank')
      if (response.data.error_code) throw new Error()
      dispatch(TransfersActions.setStatementsLoading(false))
    } catch (error) {
      // TODO: add error dispatch
      dispatch(TransfersActions.setStatementsLoading(false))
    }
  }

export const getContacts = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(TransfersActions.setTransferLoading())
    const token = await getToken()
    const idClient = await localStorage.getItem('clientId')
    const keyEndpoints = await localStorage.getItem('keyendpoints')

    const body = {
      idclient: idClient
    }
    const response = await TransferServices.contacts(
      body,
      token,
      keyEndpoints as string
    )
    Sentry.setExtras({
      data: {
        response: response.data,
        idClient,
        token
      }
    })
    if (response.status !== 200) {
      throw new Error('Error al obtener contactos')
    }
    dispatch(TransfersActions.setContacts(response.data as IContact[]))
    dispatch(TransfersActions.dismissTransferLoading())
  } catch (error) {
    Sentry.captureMessage(`Error al obtener contactos:${error}`)
    dispatch(TransfersActions.setContacts([]))
    dispatch(TransfersActions.dismissTransferLoading())
  }
}

export const validateAccount =
  (
    idAccount: string,
    setError: (arg0: string, arg1: { message: any }) => void
  ) =>
    async (dispatch: ThunkDispatchType, getState: () => RootState) => {
      try {
        dispatch(TransfersActions.setTransferLoading())
        const keyEndpoints = await localStorage.getItem('keyendpoints')

        const token = await getToken()
        let body
        if (idAccount.includes('@')) {
          body = {
            email: idAccount
          }
        } else if (/^\d+$/.test(idAccount)) {
          body = {
            cvu: idAccount
          }
        } else {
          body = {
            alias: idAccount
          }
        }
        const response: any = await TransferServices.validateAccount(
          body,
          token,
        keyEndpoints as string
        )
        Sentry.setExtras({
          data: {
            response: response.data,
            body,
            token
          }
        })
        if (response.status !== 200 && response?.data?.error_message) {
          throw ErrorMessages.DEFAULT_REQUEST_ERROR
        }

        dispatch(TransfersActions.dismissTransferLoading())
        const { accountId } = getState().transfers
        dispatch(
          TransfersActions.setTransferAccount({
            accountId,
            destinationId: idAccount
          })
        )
        return response.data
      } catch (error) {
        Sentry.captureMessage(`Error al validar cuenta:${error}`)
        setError('cuit', { message: error })
        dispatch(TransfersActions.dismissTransferLoading())
      }
    }

export const transferToAccount =
  () => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const title = {
      success: 'Transferencia realizada',
      error:
        'No pudimos procesar la transferencia. Por favor, volvé a intentarlo. '
    }
    const bodyDrawer = {
      success: 'En las próximas horas se verá reflejada en tu cuenta destino.',
      error: ''
    }
    const navigation = {
      path: '/content/your-account',
      title: 'Tu cuenta',
      fromScreen: 'transfer'
    }
    try {
      dispatch(TransfersActions.setLoadingCreateTransfer())
      const token = await getToken()
      const { transferAccount } = getState().transfers
      const keyEndpoints = await localStorage.getItem('keyendpoints')

      const response: any = await TransferServices.transfer(
        transferAccount,
        token,
        keyEndpoints as string
      )
      Sentry.setExtras({
        data: {
          response: response.data,
          transferAccount,
          token
        }
      })
      if (response.status !== 200 && response?.data?.error_message) {
        throw ErrorMessages.TRANSFER_ERROR
      }
      dispatch(TransfersActions.dismissLoadingCreateTransfer())
      dispatch(TransfersActions.clearAccountInfo())
      if (response.data.success) {
        dispatch(
          setShowDrawerSuccess(true, true, title.success, bodyDrawer.success, navigation)
        )
      }
      return response.data.success
    } catch (error) {
      Sentry.captureMessage(`Error al transferir:${error}`)
      dispatch(
        setShowDrawerError(true, title.error, bodyDrawer.error, navigation)
      )
      dispatch(TransfersActions.dismissTransferLoading())
      dispatch(TransfersActions.clearAccountInfo())
    }
  }

export const newContact =
  (contact: IDataContact, destinationId: string) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(TransfersActions.setTransferLoading())
        const token = await getToken()
        const idClient = await localStorage.getItem('clientId')
        const keyEndpoints = await localStorage.getItem('keyendpoints')
        const body = {
          ...contact,
          account: destinationId,
          email: contact.email,
          idClient
        }

        const response: any = await TransferServices.newContact(
          body,
          token,
        keyEndpoints as string
        )
        Sentry.setExtras({
          data: {
            response: response.data,
            body,
            token
          }
        })
        if (response.status !== 200) {
          throw ErrorMessages.DEFAULT_REQUEST_ERROR
        }
        dispatch(TransfersActions.dismissTransferLoading())
      } catch (error) {
        Sentry.captureMessage(`Error al crear contacto:${error}`)
        dispatch(TransfersActions.dismissTransferLoading())
        throw error
      }
    }

export const editObservation =
  (id: number, observations: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(TransfersActions.setTransferLoading())

      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const token = await getToken()
      const body = {
        id,
        observations
      }

      const response: any = await TransferServices.editObservation(
        body,
        token,
        keyEndpoints as string
      )

      Sentry.setExtras({
        data: {
          response: response.data,
          body,
          token
        }
      })
      if (response.status !== 200 && response?.data?.error_message) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      dispatch(TransfersActions.dismissTransferLoading())
    } catch (error) {
      Sentry.captureMessage(`Error al editar contacto:${error}`)
    }
  }

export const deleteContact =
  (id: number) => async (dispatch: ThunkDispatchType) => {
    const title = {
      success: 'Contacto Eliminado',
      error: 'No se pudo eliminar el contacto seleccionado.'
    }
    const bodyDrawer = {
      success: '',
      error: ''
    }
    const navigation = {
      path: '/content/your-account/my-contacts',
      title: 'Mis Contactos'
    }
    try {
      dispatch(TransfersActions.setTransferLoading())
      const keyEndpoints = await localStorage.getItem('keyendpoints')
      const token = await getToken()
      const body = {
        id
      }
      const response: any = await TransferServices.deleteContact(
        body,
        token,
        keyEndpoints as string
      )

      Sentry.setExtras({
        data: {
          response: response.data,
          body,
          token
        }
      })
      if (response.status !== 200 && response?.data?.error_message) {
        dispatch(
          setShowDrawerError(true, title.error, bodyDrawer.error, navigation)
        )
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }

      dispatch(getContacts())
      dispatch(TransfersActions.dismissTransferLoading())

      dispatch(
        setShowDrawerSuccess(true, true, title.success, bodyDrawer.success, navigation)
      )
    } catch (error) {
      Sentry.captureMessage(`Error al crear contacto:${error}`)
    }
  }

export const setTransferLimit =
  (newLimit: number | null) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(TransfersActions.setTransferLoading())
      const response: any = await TransferServices.transactionLimit({
        clientId: localStorage.getItem('clientId'),
        transactionLimit: newLimit
      })
      if (response.status !== 200) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      if (response.status === 200) {
        dispatch(TransfersActions.setTransactionLimit(newLimit))
      }
    } catch (error: any) {
      throw error?.toString()
    } finally {
      dispatch(TransfersActions.dismissTransferLoading())
    }
  }
