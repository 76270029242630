import {
  ContactsActionTypes,
  IAccount,
  IContact,
  IMovement,
  ITransferAccount,
  TransfersActionTypes
} from './types'

export const setTransferLoading = () => ({
  type: TransfersActionTypes.SET_LOADING
})

export const setLoadingCreateTransfer = () => ({
  type: TransfersActionTypes.SET_LOADING_CREATE_TRANSFER
})
export const dismissLoadingCreateTransfer = () => ({
  type: TransfersActionTypes.DISMISS_LOADING_CREATE_TRANSFER
})
export const setAccountLoading = () => ({
  type: TransfersActionTypes.SET_ACCOUNT_LOADING
})

export const dismissInfoLoading = () => ({
  type: TransfersActionTypes.DISMISS_ACCOUNT_LOADING
})

export const setStatementsLoading = (value: boolean) => ({
  type: TransfersActionTypes.SET_LOADING_STATEMENTS,
  payload: value
})

export const clearAccountInfo = () => ({
  type: TransfersActionTypes.CLEAR_ACCOUNT_INFO
})

export const dismissTransferLoading = () => ({
  type: TransfersActionTypes.DISMISS_LOADING
})

export const getShowBalance = (showBalance: any) => ({
  type: TransfersActionTypes.SET_SHOW_BALANCE,
  payload: showBalance
})

export const setShowBalance = (showBalance: any) => ({
  type: TransfersActionTypes.SET_SHOW_BALANCE,
  payload: showBalance
})

export const setMovements = (data: { movements: IMovement[]; currentPage: number; countPages: number }) => ({
  type: TransfersActionTypes.GET_MOVEMENTS,
  payload: data
})

export const setAccountInfo = (data: IAccount) => ({
  type: TransfersActionTypes.GET_ACCOUNT_INFO,
  payload: data
})

export const setContacts = (data: IContact[]) => ({
  type: TransfersActionTypes.GET_CONTACTS,
  payload: data
})

export const setTransferAccount = (data: ITransferAccount) => ({
  type: TransfersActionTypes.GET_TRANSFER_ACCOUNT,
  payload: data
})

export const setBalance = (data: number | string) => ({
  type: TransfersActionTypes.GET_BALANCE,
  payload: data
})

export const setAccountId = (data: number) => ({
  type: TransfersActionTypes.GET_ACCOUNT_ID,
  payload: data
})

export const setFilteredTransfers = (data: IMovement[]) => ({
  type: TransfersActionTypes.SET_FILTERED_MOVEMENTS,
  payload: data
})

export const newContact = (data: IContact) => ({
  type: ContactsActionTypes.NEW_CONTACT,
  payload: data
})

export const deleteContact = (id: number) => ({
  type: ContactsActionTypes.DELETE_CONTACT,
  payload: id
})

export const editObservation = (data: IContact) => ({
  type: ContactsActionTypes.EDIT_OBSERVATION,
  payload: data
})

export const logOut = () => ({
  type: TransfersActionTypes.LOGOUT
})

export const setTransactionLimit = (limit: number | null) => ({
  type: TransfersActionTypes.SET_TRANSFER_LIMIT,
  payload: limit
})

export const getTransfersRejected = () => ({
  type: TransfersActionTypes.GET_TRANSFERS_REJECTED
})
