/* eslint-disable no-control-regex */
export const emailRegex =
// eslint-disable-next-line max-len
/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const cbuRegex = /^[0-9]{22}$/ // Only 22 numbers
export const isInteger = /^[0-9]+$/ // Only numbers
export const isPrice = /^(\d?)+(\.?[0-9]{0,2}$)?$/ // 2 decimals

export const PASSWORD_PATTERN_REPEAT =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{9,}$/

export const noStartWithZero = /^[1-9a-zA-Z][0-9a-zA-Z]*$/
export const onlyLettersAndNumbers = /^[a-zA-Z0-9_.-]*$/

export const LOWECASE_LETTER = /[a-z]/
export const UPPERCASE_LETTER = /[A-Z]/
export const ACCEPT_NUMBERS = /[0-9]/
export const SPECIAL_CHARACTER = /[-.,:;{}+*´¨~«^`–…'¿=!"#_$%&/()¿¡?]/
export const ALIAS_CHARACTERS = /^[A-Za-z0-9ñÑ.-]+$/
export const SPECIAL_LETTERS = /^[^\ñÑ]*$/

export const VIRTUAL_ZERO_STRING = '0.01'
export const VIRTUAL_ZERO_NUMBER = 0.01

export enum ErrorMessages {
  MULTIPLE_OF_THIRTY = 'Las toneladas deben ser múltiplo de 30',
  REQUIRED = 'Campo requerido',
  INVALID_LOCATION = 'Por favor seleccione una localidad de nuestra lista.',
  INVALID_FORMAT = 'Formato inválido. Ej: 261.55',
  INVALID_FORMAT_QUOTAS = 'Por favor ingresa números enteros. Ej: 3',
  INVALID_CUIT = 'El CUIT/CUIL ingresado es incorrecto',
  INVALID_CUIT_HAS_HYPHEN = 'Ingrese el CUIT/CUIL sin guión.',
  INVALID_EMAIL_FORMAT = 'El email ingresado no existe',
  INTERGER = 'Por favor ingresá números',
  MAX_LENGTH = 'El campo debe tener menos de 50 carácteres.',
  ONLY_SPACES = 'No puede contener solo espacios.',
  MIN_LENGTH = 'Debe contener más de 3 carácteres.',

}

export const errorMessages = {
  pattern: 'Formato inválido'
}

export const RegExpNumbers = /^\d+$/

export const RegPhoneArg = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/

export const RegPhone = /^\d{10,14}$/

export const RegPostalCode = /([a-hj-np-z])([\d]{1,4})([a-z]{3})/i
