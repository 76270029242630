import { LegalDocumentState } from './types'

const initialState: LegalDocumentState = {
  acceptedLegalDocuments: [],
  pendingLegalDocuments: [],
  loading: false,
  loadingPending: false,
  loadingAccepted: false,
  error: undefined
}

export default initialState
