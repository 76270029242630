import React, { SetStateAction } from 'react'
import QiraPagosLogoROW from 'assets/svg/QiraPagosLogoROW'
import ButtonLine from 'components/atoms/ButtonLine'
import CustomModal from '../CustomModal'
import CustomText from 'components/atoms/CustomText'
import {
  BoxButtonsContainer,
  BoxContentContainer,
  RowWrapper,
  styles
} from './styles'
import DownloadSVG from 'assets/svg/DownloadSVG'

import { useAppSelector } from 'hooks/redux'
import { IconButton, Spinner } from '@chakra-ui/react'
import { Colors } from 'theme'
import { DocumentTypes } from '../LegalDocumentsModal/types'

interface Props {
  setIsOpen: SetStateAction<any>
  isOpen: boolean
}

const AboutModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { acceptedLegalDocuments, loadingAccepted } = useAppSelector(
    store => store.legalDocument
  )

  const handleDownload = (link: string, fileName: string) => {
    const linkSource = link

    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = `${fileName}.pdf`
    downloadLink.click()
    downloadLink.remove()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Acerca de Qira Pagos WEB"
    >
      <BoxContentContainer>
        <QiraPagosLogoROW />
        <CustomText
          customStyle={styles.customText}
          color="gray"
          weight="bold"
          size="big"
        >
          QIRA PAGOS WEB
        </CustomText>
        {loadingAccepted
          ? (
            <Spinner width={30} height={30} data-testid="spinner" />
            )
          : (
            <BoxButtonsContainer>
              {acceptedLegalDocuments.map(document => (
                <>
                  <RowWrapper key={document.id}>
                    <ButtonLine
                      customStyle={styles.button}
                      disabled={false}
                      onPress={() =>
                        handleDownload(
                          document.fullDocumentURL,
                          DocumentTypes[document.documentType]
                        )}
                    >
                      {DocumentTypes[document.documentType]}
                    </ButtonLine>
                    <IconButton
                      aria-label={`Descargar ${document.documentType}`}
                      _loading={{ color: Colors.purple }}
                      _disabled={{ cursor: 'auto' }}
                      icon={<DownloadSVG />}
                      onClick={() =>
                        handleDownload(
                          document.fullDocumentURL,
                          DocumentTypes[document.documentType]
                        )}
                    />
                  </RowWrapper>
                  {document.summaryDocumentURL && (
                  <RowWrapper key={document.id}>
                    <ButtonLine
                      customStyle={styles.button}
                      disabled={false}
                      onPress={() =>
                        handleDownload(
                          document.summaryDocumentURL,
                          `${DocumentTypes[document.documentType]} (Resumen)`
                        )}
                    >
                      {`${DocumentTypes[document.documentType]} (Resumen)`}
                    </ButtonLine>
                    <IconButton
                      aria-label={`Descargar ${
                        DocumentTypes[document.documentType]
                      } (Resumen)`}
                      _loading={{ color: Colors.purple }}
                      _disabled={{ cursor: 'auto' }}
                      icon={<DownloadSVG />}
                      onClick={() =>
                        handleDownload(
                          document.summaryDocumentURL,
                          `${DocumentTypes[document.documentType]} (Resumen)`
                        )}
                    />
                  </RowWrapper>
                  )}
                </>
              ))}
            </BoxButtonsContainer>
            )}
      </BoxContentContainer>
    </CustomModal>
  )
}

export default AboutModal
