import { Countries } from 'constants/common'

const extractPhoneNumberInfo = (telephoneNumber: string) => {
  const cleanedNumber = telephoneNumber.replace(/^\+/, '')

  const country = Countries.find(country =>
    cleanedNumber.startsWith(country.code.toString())
  )

  if (country) {
    const areaCode = country.code.toString()
    const phone = cleanedNumber.slice(areaCode.length)

    return {
      areaCode,
      phone
    }
  }

  return {
    areaCode: Countries[0].code.toString(),
    phone: cleanedNumber
  }
}

export default extractPhoneNumberInfo
