import React from 'react'
import WheatItem from 'assets/svg/WheatItem'
import TransferRecieve from '@qirapagos/lib/assets/svg/TransferReceive'
import TruckQuota from 'assets/svg/TruckQuota'
import { blue, green, red, yellow } from '@qirapagos/lib/theme/colors'
import TicketSVG from 'assets/svg/TicketSVG'
import { ActivityMovement } from 'store/activity/types'
import { ReactComponent as Hourglass } from 'assets/svg/Hourglass.svg'
import { getTransferStatusIcon, getTransferStatusText } from '../MovementItem'
import { MovementStatus } from '../MovementItem/constants'

export enum TypeMovementsActivity {
  MOVEMENT_TRANSFER_DEBIT = 'MOVEMENT_TRANSFER_DEBIT',
  MOVEMENT_TRANSFER_CREDIT = 'MOVEMENT_TRANSFER_CREDIT',
  MOVEMENT_DEBIT_TAX = 'MOVEMENT_DEBIT_TAX',
  MOVEMENT_CREDIT_TAX = 'MOVEMENT_CREDIT_TAX',
  MOVEMENT_PENDING = 'MOVEMENT_PENDING',
  SALE_ORDER_REJECTED = 'SALE_ORDER_REJECTED',
  SALE_ODER_COUNTER_OFFER = 'SALE_ODER_COUNTER_OFFER',
  SALE_ORDER_PENDING = 'SALE_ORDER_PENDING',
  SALE_ORDER_MIGRATED = 'SALE_ORDER_MIGRATED',
  SALE_ORDER_REMOVED = 'SALE_ORDER_REMOVED',
  QUOTA_AWARDED = 'QUOTA_AWARDED',
  QUOTA_REJECTED = 'QUOTA_REJECTED',
  QUOTA_CANCELED = 'QUOTA_CANCELED',
  QUOTA_REQUIRED = 'QUOTA_REQUIRED',
  APPLICATIONS = 'APPLICATIONS',
  LIQUIDATION = 'LIQUIDATION',
  PERFORMANCE = 'PERFORMANCE'
}

export const getInfo = (activity: ActivityMovement) => {
  switch (activity.movementType) {
    case TypeMovementsActivity.MOVEMENT_TRANSFER_DEBIT:
      return {
        title: getTransferStatusText(activity.status as MovementStatus),
        icon: getTransferStatusIcon(activity.status as MovementStatus),
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.MOVEMENT_TRANSFER_CREDIT:
      return {
        title: 'Recibiste dinero',
        icon: <TransferRecieve />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.MOVEMENT_DEBIT_TAX:
      return {
        title: 'Percepciones',
        icon: <TicketSVG />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.MOVEMENT_CREDIT_TAX:
      return {
        title: 'Retenciones',
        icon: <TicketSVG />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.MOVEMENT_PENDING:
      return {
        title: 'Transacción pendiente',
        icon: <Hourglass />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.SALE_ORDER_REJECTED:
      return {
        title: 'Contrato rechazado',
        icon: <WheatItem color={red} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.SALE_ORDER_REMOVED:
      return {
        title: 'Contrato eliminado',
        icon: <WheatItem color={red} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.SALE_ODER_COUNTER_OFFER:
      return {
        title: 'Contra oferta recibida',
        icon: <WheatItem color={blue} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.SALE_ORDER_MIGRATED:
      return {
        title: 'Contrato aprobado',
        icon: <WheatItem color={green} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.SALE_ORDER_PENDING:
      return {
        title: 'Orden de venta solicitada',
        icon: <WheatItem color={yellow} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.QUOTA_AWARDED:
      return {
        title: 'Cupo otorgado',
        icon: <TruckQuota shownAtActivity color={green} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.QUOTA_REJECTED:
      return {
        title: 'Cupo rechazado',
        icon: <TruckQuota color={red} shownAtActivity />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.QUOTA_CANCELED:
      return {
        title: 'Cupo anulado',
        icon: <TruckQuota color={red} shownAtActivity />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.QUOTA_REQUIRED:
      return {
        title: 'Cupo solicitado',
        icon: <TruckQuota shownAtActivity color={yellow} />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
    case TypeMovementsActivity.PERFORMANCE:
      return {
        title: 'Crédito rendimiento',
        icon: <TransferRecieve />,
        amount: activity.amount,
        owner: activity.owner,
        date: activity.date
      }
      // TODO RRQP-2444 add applications and liquidations
    default:
      return {
        title: '',
        icon: null,
        amount: '',
        owner: ''
      }
  }
}
