import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { TSetState } from 'types/generics'
import { isCuitValid } from '@qirapagos/lib/utils/common'
import { useFormik } from 'formik'
import { TextField } from 'components/shared/TextField'
import { getEnterprise } from 'store/enterprise/thunks'
import { useAppSelector } from 'hooks/redux'
import * as Yup from 'yup'
import { ContinueButton as Button, InputsContainer, StepOneContainer } from './styles'
import { clearEnterpriseState } from 'store/enterprise/actions'

enum StepOneErrors {
  ALREADY_REGISTERED = 'CUIT ya registrado.',
  MUST_BE_ENTERPRISE = 'Debe ser una empresa.',
  INVALID_CUIT = 'CUIT inválido.',
  REQUIRED = 'Es requerido.'
}

interface FormValues {
  cuit: string;
  name: string;
}

interface TitleInfo {
  title: string;
  isComplete: boolean;
  setComplete?: (value: boolean) => void;
}

interface Props {
  setIndex: TSetState<number>;
  titlesInfo: TitleInfo;
}

const formNewEnterpriseValidator = Yup.object().shape({
  cuit: Yup.string()
    .required(StepOneErrors.REQUIRED)
    .test('is-cuit-valid', StepOneErrors.INVALID_CUIT, value => {
      const { canValidate, isValid, isBusiness } = isCuitValid(value)
      return canValidate && isValid && isBusiness
    })
})

export const StepOne: React.FC<Props> = props => {
  const { setIndex, titlesInfo } = props
  const { enterprise, isLoading } = useAppSelector(state => state.enterprise)
  const dispatch = useDispatch()

  const formik = useFormik<FormValues>({
    initialValues: {
      cuit: '',
      name: ''
    },
    validationSchema: formNewEnterpriseValidator,
    validateOnChange: false,
    onSubmit: () => {
      setIndex(1)
      titlesInfo.setComplete && titlesInfo.setComplete(true)
    }
  })

  const formikRef = useRef(formik)

  const { cuit } = formik.values

  const { canValidate, isValid } = isCuitValid(cuit)

  const isDisabled = !!formik.errors.cuit || !!formik.errors.name || !enterprise

  useEffect(() => {
    const checkCuit = async () => {
      try {
        await dispatch(getEnterprise(cuit))
      } catch (error) {
        formikRef.current.setFieldError('name', StepOneErrors.INVALID_CUIT)
      }
    }

    if (canValidate && isValid && cuit) {
      formikRef.current.setFieldError('name', '')
      checkCuit()
    }
  }, [canValidate, isValid, cuit, dispatch])

  useEffect(() => {
    formikRef.current.setFieldTouched('name', true, false)
    if (enterprise && !isLoading) {
      if (enterprise.registered) {
        formikRef.current.setFieldError('name', StepOneErrors.ALREADY_REGISTERED)
      }
      if (!enterprise.isBusiness) {
        formikRef.current.setFieldError('name', StepOneErrors.MUST_BE_ENTERPRISE)
      }
      formikRef.current.setFieldValue('name', enterprise.name)
    }
  }, [isLoading, enterprise])

  useEffect(() => {
    formikRef.current.setFieldValue('name', '')
  }, [])

  useEffect(() => {
    localStorage
      .setItem('cuit', cuit)
  }, [cuit])

  return (
    <StepOneContainer>
      <InputsContainer>
        <TextField
          formik={formik}
          name="cuit"
          label="CUIT/CUIL"
          type="string"
          onChange={() => {
            formik.setFieldValue('name', '')
            dispatch(clearEnterpriseState())
          }}
        />
        <TextField
          formik={formik}
          name="name"
          label="Razón social / Nombre y apellido"
          type="string"
          disabled
        />
      </InputsContainer>
      <Button
        onPress={formik.handleSubmit}
        loading={isLoading}
        disabled={isDisabled}
      >
        Continuar
      </Button>
    </StepOneContainer>
  )
}

export default StepOne
