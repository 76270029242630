import React, { SetStateAction, useEffect, useState } from 'react'
import ButtonLine from 'components/atoms/ButtonLine'
import CustomModal from '../CustomModal'
import CustomText from 'components/atoms/CustomText'
import {
  AcceptButton,
  BoxButtonsContainer,
  BoxContentContainer,
  ButtonContainer,
  ModalButton,
  RowWrapper,
  styles
} from './styles'
import DownloadSVG from 'assets/svg/DownloadSVG'

import { useAppSelector } from 'hooks/redux'
import { IconButton } from '@chakra-ui/react'
import { Colors } from 'theme'
import { DocumentTypes, ModalDescription } from './types'
import { useDispatch } from 'react-redux'
import { acceptLegalDocument } from 'store/legal-document/thunks'
import { DocumentType } from 'store/legal-document/types'

interface Props {
  setIsOpen: SetStateAction<any>
  isOpen: boolean
}

const LegalDocumenstModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch()
  const { pendingLegalDocuments, loading } = useAppSelector(
    store => store.legalDocument
  )
  const { isRepresentative } = useAppSelector(store => store.representation)

  const [hasModalBeenShown, setHasModalBeenShown] = useState(false)

  const handleDownload = (link: string, fileName: string) => {
    const linkSource = link

    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = `${fileName}.pdf`
    downloadLink.click()
    downloadLink.remove()
  }

  const pendingDocumentsFiltered = isRepresentative
    ? pendingLegalDocuments
    : pendingLegalDocuments.filter(
      document => document.documentType !== DocumentType.TERMS_AND_CONDITIONS_REPRESENTATIVE
    )

  const activeDocuments = pendingDocumentsFiltered.filter(
    document => new Date(document.validityDate) <= new Date()
  )

  const modalDescription = activeDocuments.length > 0
    ? ModalDescription.MANDATORY_DOCUMENTS
    : ModalDescription.OPTIONAL_DOCUMENTS

  const handleAccept = async (legalDocumentId: number) => {
    const isActiveDocument = activeDocuments.find(
      document => document.id === legalDocumentId
    )
    await dispatch(acceptLegalDocument(legalDocumentId))
    if (isActiveDocument) {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (pendingDocumentsFiltered.length === 0) {
      setIsOpen(false)
      setHasModalBeenShown(false)
    }
    if (!hasModalBeenShown && pendingDocumentsFiltered.length > 0) {
      setIsOpen(true)
      setHasModalBeenShown(true)
    }
  }, [hasModalBeenShown, pendingDocumentsFiltered, setIsOpen])

  return (
    <CustomModal
      paddingTop="20px"
      showCloseButton={!activeDocuments.length}
      isOpen={isOpen}
      onClose={() => {
        if (!activeDocuments.length) {
          setIsOpen(false)
        }
      }}
      title="Actualización de Documentación"
    >
      <BoxContentContainer>
        <CustomText
          customStyle={styles.customText}
          align='justify'
          color="gray"
          weight="regular"
          size="medium"
        >
          {modalDescription}
        </CustomText>
        <BoxButtonsContainer>
          {pendingDocumentsFiltered.map(document => (
            <RowWrapper key={document.id}>
              <ButtonLine
                customStyle={styles.button}
                disabled={false}
                onPress={() =>
                  handleDownload(
                    document.fullDocumentURL,
                    DocumentTypes[document.documentType]
                  )}
              >
                {DocumentTypes[document.documentType]}
              </ButtonLine>
              <IconButton
                aria-label={`Descargar ${document.documentType}`}
                _loading={{ color: Colors.purple }}
                _disabled={{ cursor: 'auto' }}
                icon={<DownloadSVG />}
                onClick={() =>
                  handleDownload(
                    document.fullDocumentURL,
                    DocumentTypes[document.documentType]
                  )}
              />
              <AcceptButton
                disabled={loading}
                onPress={() => handleAccept(document.id)}
              >
                Aceptar
              </AcceptButton>
            </RowWrapper>
          ))}
        </BoxButtonsContainer>
        {!activeDocuments.length && (
          <ButtonContainer>
            <ModalButton
              secondary
              onPress={() => setIsOpen(false)}
            >
              Omitir
            </ModalButton>
          </ButtonContainer>
        )}
      </BoxContentContainer>
    </CustomModal>
  )
}

export default LegalDocumenstModal
