import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BoxBalance, DirectAccess, YourActivity } from 'components'
import { getUserBalance } from 'store/transfers/thunks'
import WalletDesktopSkeleton from 'assets/skeletons/WalletDesktopSkeleton'
import useNavigation from 'hooks/useNavigation'
import { SCREEN_TITLES } from 'utils/navigation'
import image from '@qirapagos/web/src/assets/images/banner.png'
import Helmet from 'components/utilities/Helmet'
import { getMultiEnterpriseAssociations } from 'store/menu/thunks'
import { useAppSelector } from 'hooks/redux'
import {
  Container,
  ViewLeftColumn,
  ViewRightColumn,
  ViewColumnsContainer,
  ViewSubContainer,
  ViewBannerContainer,
  ImageAdvertising
} from './styles'
import { getActivity } from 'store/activity/thunks'
import { getNotifications } from 'store/notifications/thunks'
import { onPushNotificationCallback } from 'config/firebase'

const Wallet: React.FC = () => {
  const { isLoading, balance } = useAppSelector(state => state.transfers)
  const { isLoading: isLoadingAuth } = useAppSelector(state => state.auth)
  const { isLoading: isLoadingActivity, activity } = useAppSelector(
    state => state.activity
  )
  const { loadingPending } = useAppSelector(state => state.legalDocument)
  const { multiEnterpriseAssociation } = useAppSelector(state => state.menu)
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  useEffect(() => {
    onPushNotificationCallback(() => {
      dispatch(getNotifications())
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(getActivity(!!activity))
    if (Number(balance) === 0) {
      dispatch(getUserBalance())
    }
    if (!multiEnterpriseAssociation) {
      dispatch(getMultiEnterpriseAssociations())
    }
  }, [multiEnterpriseAssociation, balance, dispatch])

  const goToMyData = useCallback(() => {
    onNavigate('/content/my-data', {
      hasBackArrow: true
    })
  }, [onNavigate])

  if (
    loadingPending ||
    isLoading ||
    isLoadingActivity ||
    isLoadingAuth ||
    !activity
  ) {
    return <WalletDesktopSkeleton />
  }

  return (
    <Container>
      <Helmet title={SCREEN_TITLES.WALLET} />
      <ViewSubContainer>
        <ViewBannerContainer>
          <a
            target="_blank"
            href="https://www.qiraglobal.com/"
            rel="noreferrer"
          >
            <ImageAdvertising src={image} alt="Advertising" />
          </a>
        </ViewBannerContainer>
        <ViewColumnsContainer>
          <ViewLeftColumn>
            <BoxBalance
              title="Mi billetera"
              subtitle="Saldo"
              buttonText="Mis datos"
              onPressButton={goToMyData}
            />
            <DirectAccess />
          </ViewLeftColumn>
          <ViewRightColumn>
            <YourActivity />
          </ViewRightColumn>
        </ViewColumnsContainer>
      </ViewSubContainer>
    </Container>
  )
}
export default Wallet
