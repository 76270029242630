import { instance } from '.'
import { insertKeyEndpoint } from './utils'
import { LegalDocument } from 'store/legal-document/types'

interface Response<T> {
  data: T
  error: boolean
  message: string
}

export const getPendingLegalDocuments = async () => {
  return instance.get<Response<LegalDocument[]>>(
    insertKeyEndpoint('legal-document/pending')
  )
}

export const getAcceptedLegalDocuments = async () => {
  return instance.get<Response<LegalDocument[]>>(
    insertKeyEndpoint('legal-document/accepted')
  )
}

export const acceptLegalDocument = async (legalDocumentId: number) => {
  return instance.post(insertKeyEndpoint('legal-document/accept'), {
    legalDocumentId
  })
}
