
export interface AuthState {
  deviceId: string
  isDeviceIdUpdating: boolean
  error: undefined
}

export enum AuthActionTypes {
  UPDATE_DEVICE_ID_PENDING = '@AUTH:UPDATE_DEVICE_ID_PENDING',
  UPDATE_DEVICE_ID_FULFILLED = '@AUTH:UPDATE_DEVICE_ID_FULFILLED',
  UPDATE_DEVICE_ID_REJECTED = '@AUTH:UPDATE_DEVICE_ID_REJECTED',
}

export enum ErrorCodes {
  PASSWORD_EXPIRED = 16,
  PASSWORD_LOCKED = 17,
  USER_SUSPENDED = 21,
}
