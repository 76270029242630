import styled from 'styled-components'
import Button from 'components/atoms/Button'

export const styles = {
  button: {
    margin: 0,
    padding: 0,
    width: 200
  },
  customText: {
    marginTop: 0,
    lineHeight: 2,
    marginBottom: 5,
    width: '95%'
  }
}

export const BoxContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
`

export const BoxButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
  gap: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 25px;
  width: 45%;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

export const AcceptButton = styled(Button)`
  width: 120px;
  height: 40px;
  margin: 0;
`

export const ModalButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 0;
  width: 80%;
  height: 50px;
`
