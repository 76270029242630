import initialState from './initialState'
import { LegalDocumentState, LegalDocumentActionTypes } from './types'

interface Action<T = null> {
  type: string
  payload?: T
}

export default function legalDocumentReducer (
  state = initialState,
  action: Action
): LegalDocumentState {
  switch (action.type) {
    case LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_PENDING:
      return {
        ...state,
        loadingPending: true,
        error: initialState.error
      }
    case LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_FULFILLED:
      return {
        ...state,
        pendingLegalDocuments:
          action.payload ?? initialState.pendingLegalDocuments,
        loadingPending: initialState.loadingPending
      }
    case LegalDocumentActionTypes.GET_PENDING_LEGAL_DOCUMENTS_REJECTED:
      return {
        ...state,
        loadingPending: initialState.loadingPending,
        error: action.payload ?? initialState.error
      }
    case LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_PENDING:
      return {
        ...state,
        loadingAccepted: true,
        error: initialState.error
      }
    case LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_FULFILLED:
      return {
        ...state,
        acceptedLegalDocuments:
          action.payload ?? initialState.acceptedLegalDocuments,
        loadingAccepted: initialState.loadingAccepted
      }
    case LegalDocumentActionTypes.GET_ACCEPTED_LEGAL_DOCUMENTS_REJECTED:
      return {
        ...state,
        loadingAccepted: initialState.loadingAccepted,
        error: action.payload ?? initialState.error
      }
    case LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: initialState.error
      }
    case LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_FULFILLED:
      return {
        ...state,
        loading: initialState.loading,
        pendingLegalDocuments: state.pendingLegalDocuments.filter(
          legalDocument => legalDocument.id !== Number(action.payload)
        )
      }
    case LegalDocumentActionTypes.ACCEPT_LEGAL_DOCUMENT_REJECTED:
      return {
        ...state,
        loading: initialState.loading,
        error: action.payload ?? initialState.error
      }
    default:
      return state
  }
}
